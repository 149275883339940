import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { openDrawer } from '../../../common/drawers/drawerActions'
import ListItem from './ListItem'
import { useQueryParamString } from 'react-use-query-param-string'

const ListDashboard = ({ data }) => {
  const dispatch = useDispatch()
  const currentDrawer = useSelector((state) => state.drawers)

  const [locId, setLocId] = useQueryParamString('loc', '')
  const [lat] = useQueryParamString('lat', '')
  const [lng] = useQueryParamString('lng', '')

  // Remove the query param if the drawer is closed
  useEffect(() => {
    if (!currentDrawer) {
      setLocId(undefined)
    }
  }, [currentDrawer])

  // Open the drawer if the url contains a locationId (locId) or any time the locId changes
  useEffect(() => {
    if (locId) {
      dispatch(
        openDrawer('LocationDetailDrawer', {
          data: data.find((loc) => loc.id == locId)
        })
      )
      setLocId(locId) // reset it as first time load unsets it
    }
  }, [locId])

  return (
    <>
      <div className='list-view'>
        <div className='mt-3 px-4 d-none d-md-block'>
          <div className='container-fluid'>
            <div className='row'>
              <div className='col-5 d-flex align-items-end'>
                <p className='text-uppercase text-small'>Organization</p>
              </div>
              {lat && lng && (
                <div className='col-3 d-flex align-items-end text-center'>
                  <p className='text-uppercase text-small'>
                    Distance from search
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
        {data.map((location, index) => (
          <div key={location.id || index} className='list-item'>
            <ListItem
              key={location.id || index}
              isActive={location.id === locId}
              data={location}
            />
          </div>
        ))}
      </div>
    </>
  )
}

export default ListDashboard
