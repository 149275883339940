import GoogleMap from '../../Maps/Map'
import React, { useState, useEffect } from 'react'
import LocationIcon from './LocationIcon'
import { useDispatch, useSelector } from 'react-redux'
import { useQueryParamString } from 'react-use-query-param-string'
import { openDrawer } from '../../../common/drawers/drawerActions'

const MAX_ZOOM = 5
const SEARCH_ZOOM = 10
const DRAWER_OPEN_ZOOM = 14

const MapDashboard = ({ data }) => {
  const dispatch = useDispatch()
  const currentDrawer = useSelector((state) => state.drawers)

  const [mapCenter, setMapCenter] = useState({
    lat: 47.116386,
    lng: -99.299591
  }) // set to center of USA
  // const [lat, setLat] = useQueryParam('lat', NumberParam)
  // const [lng, setLng] = useQueryParam('lng', NumberParam)
  // const [orgName, setOrgName] = useQueryParam('orgName', StringParam)
  // const [locId, setLocId] = useQueryParam('loc', StringParam)
  const [locId, setLocId] = useQueryParamString('loc', '')
  const [lat, setLat] = useQueryParamString('lat', '')
  const [lng, setLng] = useQueryParamString('lng', '')
  const [orgName, setOrgName] = useQueryParamString('orgName', '')

  const [mapZoom, setMapZoom] = useState(MAX_ZOOM)
  const [loadedMap, setLoadedMap] = useState()
  const [mapRefDisplayed, setMapRefDisplayed] = useState() // Map reference for the map thats displayed on screen

  // called upon when search is used
  useEffect(() => {
    if (lat && lng && !locId && mapRefDisplayed) {
      setMapCenter({ lat: parseInt(lat), lng: parseInt(lng) })
      mapRefDisplayed.setZoom(SEARCH_ZOOM)
    }
    // if nothing within url just set it to the default zoom
    if (!lat && !lng && !locId && mapRefDisplayed) {
      mapRefDisplayed.setZoom(MAX_ZOOM)
    }
  }, [lat, lng, locId, mapRefDisplayed])

  // Remove the query param if the drawer is closed
  useEffect(() => {
    if (!currentDrawer && mapRefDisplayed) {
      setLocId(undefined)
      // mapRefDisplayed.setZoom(DRAWER_OPEN_ZOOM);
    }
  }, [currentDrawer, mapRefDisplayed])

  // Open the drawer if the url contains a locationId (locId) or any time the locId changes
  useEffect(() => {
    if (locId && mapRefDisplayed) {
      const location = data.find((loc) => loc.id == locId)
      dispatch(
        openDrawer('LocationDetailDrawer', {
          data: location
        })
      )
      setLocId(locId) // reset it as first time load unsets it
      mapRefDisplayed.setZoom(DRAWER_OPEN_ZOOM)
      setMapCenter([
        parseFloat(location.locationServiceArea.rescueLat),
        parseFloat(location.locationServiceArea.rescueLng)
      ])
      setLat(parseFloat(location.locationServiceArea.rescueLat))
      setLng(parseFloat(location.locationServiceArea.rescueLng))
      setOrgName(location.title)
    }
  }, [locId, mapRefDisplayed])

  return (
    <div className='map-wrapper'>
      <GoogleMap
        center={mapCenter}
        zoom={mapZoom}
        location={{ lat, lng }}
        options={{
          minZoom: MAX_ZOOM,
          fullscreenControl: false,
          scrollwheel: true,
          clickableIcons: false,
          gestureHandling: 'greedy'
        }}
        bootstrapURLKeys={{
          key: process.env.GATSBY_MAP_KEY,
          libraries: ['places', 'geometry']
        }}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map }) => setMapRefDisplayed(map)}
        onChange={(map) => {
          setLoadedMap(map)
        }}
      >
        {data &&
          data.map((location) => {
            return (
              <LocationIcon
                key={location.id}
                type={location.organizationInformation.organizationtype}
                lat={location.locationServiceArea.rescueLat}
                lng={location.locationServiceArea.rescueLng}
                isActive={location.id === locId}
                onClick={() => {
                  setMapCenter([
                    parseFloat(location.locationServiceArea.rescueLat),
                    parseFloat(location.locationServiceArea.rescueLng)
                  ])
                  mapRefDisplayed.setZoom(DRAWER_OPEN_ZOOM)
                  setLocId(location.id)
                }}
              />
            )
          })}
      </GoogleMap>
    </div>
  )
}

export default MapDashboard
