import React, { useEffect, useState } from 'react'
import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete'
import Label from './label'
import GoogleMap from '../Maps/Map'
import LocationIcon from '../Maps/Marker'
import AddressInputAutoCompleteContainer from './AddressInputAutoCompleteContainer'
import { Wrapper, Status } from '@googlemaps/react-wrapper'

const DEFAULT_LOCATION = [38.9072, -77.0369]

const AddressInputMap = ({
  className,
  hint,
  label,
  placeholder,
  required,
  field,
  onChange,
  onSelect,
  defaultLocation,
  showMap = true,
  form: { errors, setFieldValue, submitCount, values }
}) => {
  const status = submitCount > 0 && errors[field.name] ? `is-invalid` : ``
  const [state, setState] = useState({
    mapApiLoaded: false,
    mapInstance: null,
    mapApi: null
  })

  const [places, setPlaces] = useState([
    {
      placeId: '123',
      name: '',
      marker: DEFAULT_LOCATION
    }
  ])
  const [mapLocation, setMapLocation] = useState(DEFAULT_LOCATION)

  const {
    // mapApiLoaded,
    mapInstance
    // mapApi
  } = state

  useEffect(() => {
    if (defaultLocation && defaultLocation.geom && mapInstance) {
      const coords = [
        defaultLocation.geom.coordinates[1],
        defaultLocation.geom.coordinates[0]
      ]
      setMapLocation(coords)
      mapInstance.setZoom(15)
      setPlaces([
        {
          placeId: '123',
          marker: coords
        }
      ])
    }
    /* eslint-disable */
  }, [mapInstance])

  function apiHasLoaded(map, maps) {
    setState({
      mapApiLoaded: true,
      mapInstance: map,
      mapApi: maps
    })
  }

  function updateMap(place) {
    if (!place.geometry) return
    mapInstance.setCenter(place.geometry.location)
    mapInstance.setZoom(15)
    setPlaces([
      {
        placeId: place.place_id,
        marker: [place.geometry.location.lat(), place.geometry.location.lng()]
      }
    ])
    // setMapLocation(place);
  }

  // https://stackoverflow.com/a/61436295
  const getDataFromGeoCoderResult = (geoCoderResponse) => {
    const geoCoderResponseHead = geoCoderResponse[0]
    const geoCoderData = geoCoderResponseHead.address_components
    const isEmptyData = !geoCoderResponseHead || !geoCoderData

    if (isEmptyData) return {}

    return geoCoderData.reduce((acc, { types, long_name: value }) => {
      const type = types[0]
      switch (type) {
        case 'route':
          return { ...acc, street_name: value }
        case 'locality':
          return { ...acc, city: value }
        case 'country':
          return { ...acc, country: value }
        case 'postal_code':
          return { ...acc, zip: value }
        case 'street_number':
          return { ...acc, street_number: value }
        case 'administrative_area_level_1':
          return { ...acc, state: value }
        case 'administrative_area_level_2':
          return { ...acc, county: value }
        default:
          return acc
      }
    }, {})
  }

  const render = (status) => {
    switch (status) {
      case Status.LOADING:
        return ''
      case Status.FAILURE:
        return ''
      case Status.SUCCESS:
        return (
          <>
            <Label label={label} hint={hint} />
            <PlacesAutocomplete
              searchOptions={{ componentRestrictions: { country: ['us'] } }} // region lock to US
              value={field.value || ''}
              onChange={onChange}
              className='position-relative'
              onSelect={(address) => {
                geocodeByAddress(address).then((results) => {
                  const geoCodeData = getDataFromGeoCoderResult(results)
                  const location = {
                    ...geoCodeData,
                    formatted_address: results[0].formatted_address,
                    // place_id: results[0].place_id, // TODO: Bring this into postgres
                    geom: {
                      type: 'Point',
                      coordinates: [
                        results[0].geometry.location.lng(),
                        results[0].geometry.location.lat()
                      ]
                    },
                    latitude: results[0].geometry.location.lat(),
                    longitude: results[0].geometry.location.lng()
                  }
                  setFieldValue('location', location)
                  if (showMap) {
                    updateMap(results[0])
                  }
                })
                setFieldValue(field.name, address)
              }}
            >
              {({
                getInputProps,
                suggestions,
                getSuggestionItemProps,
                loading
              }) => (
                <>
                  {showMap ? (
                    <div className='position-relative'>
                      <AddressInputAutoCompleteContainer
                        inputProps={getInputProps}
                        suggestions={suggestions}
                        suggestionItemProps={getSuggestionItemProps}
                        loading={loading}
                        placeholder={placeholder}
                        showMap={showMap}
                        status={status}
                        required={required}
                      />
                    </div>
                  ) : (
                    <AddressInputAutoCompleteContainer
                      inputProps={getInputProps}
                      suggestions={suggestions}
                      suggestionItemProps={getSuggestionItemProps}
                      loading={loading}
                      placeholder={placeholder}
                      showMap={showMap}
                      status={status}
                      required={required}
                    />
                  )}
                </>
              )}
            </PlacesAutocomplete>

            {submitCount > 0 && errors[field.name] && (
              <div className='invalid-feedback'>{errors[field.name]}</div>
            )}
            {showMap && (
              <div className='text-white map-input-wrapper'>
                <div className='row'>
                  <div className='col-12'>
                    <div
                      style={{ width: '100%', height: '25vh' }}
                      className='rounded overflow-hidden'
                    >
                      <GoogleMap
                        defaultZoom={10}
                        center={mapLocation}
                        options={{
                          panControl: false,
                          mapTypeControl: false,
                          scrollwheel: false,
                          gestureHandling: 'none',
                          fullscreenControl: false,
                          zoomControl: false
                        }}
                        bootstrapURLKeys={{
                          key: process.env.GATSBY_MAP_KEY,
                          libraries: ['places', 'geometry']
                        }}
                        yesIWantToUseGoogleMapApiInternals
                        onGoogleApiLoaded={({ map, maps }) =>
                          apiHasLoaded(map, maps)
                        }
                      >
                        {places &&
                          places.map((place, i) => {
                            return (
                              <LocationIcon
                                key={`marker_${i}`}
                                className={`icon-map`}
                                lat={place.marker[0]}
                                lng={place.marker[1]}
                                type={values['type'] || 'need'}
                              />
                            )
                          })}
                      </GoogleMap>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </>
        )
    }
  }

  return (
    <Wrapper
      apiKey={process.env.GATSBY_MAP_KEY}
      render={render}
      libraries={['places', 'geometry']}
    />
  )
}

export default AddressInputMap
