import React, { useEffect } from 'react'
import { Field, Form, Formik } from 'formik'
import * as Yup from 'yup'
import { useQueryParamString } from 'react-use-query-param-string'
import AddressInputMap from '../Forms/AddressInputMap'
// import NodeGeocoder from 'node-geocoder'

const Search = () => {
  useEffect(() => {
    const init = async () => {
      const options = {
        provider: 'google',
        apiKey: process.env.GATSBY_MAP_KEY
      }
      // const geocoder = NodeGeocoder(options);
      // const geoData = await geocoder.geocode('1803 George Washington Way, Richland, Washington, United States')
      // console.log(geoData)
    }
    init()
  }, [])

  const [lat, setLat] = useQueryParamString('lat', '')
  const [lng, setLng] = useQueryParamString('lng', '')

  const initialValues = {
    search_location: ''
  }

  const validationSchema = Yup.object().shape({
    search_location: Yup.string().required('Enter a location to search')
  })

  const handleSubmit = (values) => {
    const {
      location: { latitude, longitude }
    } = values
    setLat(parseInt(latitude))
    setLng(parseInt(longitude))
  }

  return (
    <div className='grid-item search'>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values) => handleSubmit(values)}
      >
        {({
          values,
          setFieldValue,
          dirty,
          isValid,
          resetForm,
          isSubmitting,
          submitCount,
          postError
        }) => (
          <Form id='location-form'>
            <>
              <h1>Search the Directory</h1>
              <p>Find a food rescue organization near you</p>
              <div id='nomFields'>
                <Field
                  name='search_location'
                  type='text'
                  component={AddressInputMap}
                  placeholder='Enter Your Address'
                  showMap={false}
                  className='position-relative'
                  onChange={(value) => {
                    if (!value && lat && lng) {
                      setLat(undefined)
                      setLng(undefined)
                    }
                    setFieldValue('search_location', value)
                  }}
                />
              </div>
              <button
                type='submit'
                form='location-form'
                className={`btn btn-dark ${
                  dirty || isValid ? '' : 'btn-disabled'
                } `}
                disabled={dirty || isValid ? false : true}
              >
                Search
              </button>
            </>

            {/* <FormikDebug /> */}
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default Search
