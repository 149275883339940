import React from 'react'
import _ from 'lodash'
import { domToReact } from 'html-react-parser'
import SharedComponents from '../SharedComponents'
import format from "date-fns/format"


export const formatDate = date => {
  let dateFormated = "";
  if (date) {
    dateFormated = format(new Date(date), "MMMM dd, yyyy");
  }
  return dateFormated;
}

export const objectToArray = object => {
  if (object) {
    return Object.entries(object).map(e =>
      Object.assign({}, e[1], { id: e[0] })
    );
  }
}

export const formatToPhone = number => {
  const input = number.replace(/\D/g, "").substring(0, 10); // First ten digits of input only
  const zip = input.substring(0, 3);
  const middle = input.substring(3, 6);
  const last = input.substring(6, 10);
  return `(${zip}) ${middle} - ${last}`;
}

export const formatMoney = moneyAmount => {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD"
  });

  return formatter.format(moneyAmount);
}

export function getParseOptions(props) {
  return ({
    replace: ({ attribs, name, children }) => {
      if (!attribs) return
      if (attribs.id === 'pageFooter' || attribs.id === 'pageHeader' || name === 'script' || name === 'head') return ('')
      if (name === 'html' || name === 'body') {
        return <>{domToReact(children, getParseOptions(props))}</>
      }

      if (attribs.id === 'pageContent') {
        return <>{domToReact(children, getParseOptions(props))}</>
      }

      if (name.includes('-')) {
        const component = _.upperFirst(_.camelCase(name))
        return React.createElement(SharedComponents[component], { ...attribs, props })
      }
    }
  })
}

export function getElementParseOptions() {
  return ({
    replace: ({ attribs, name }) => {
      if (!attribs) return;

      if (name.includes('-')) {
        const component = _.upperFirst(_.camelCase(name))
        // @TODO: Write logic to check if component isExists. If not - throw error
        return React.createElement(SharedComponents[component], attribs)
      }
    }
  })
}

export function sortOptions(arr) {
  const options = arr.map(item => {
    const { termTaxonomyId, name } = item.node

    return {
      value: termTaxonomyId,
      label: name === 'Uncategorized' ? 'Other' : name
    }
  })

  const i = options.findIndex(o => o.label === "Other")
  const newArray = options.concat(options.splice(i, 1))

  return newArray
}

export function formatFileSize(bytes, si = true, dp = 1) {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + ' B';
  }

  const units = si
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  let u = -1;
  const r = 10 ** dp;

  do {
    bytes /= thresh;
    ++u;
  } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);


  return bytes.toFixed(dp) + ' ' + units[u];

}

const toRad = (Value) => {
  /** Converts numeric degrees to radians */
  return Value * Math.PI / 180;
}


/**
 * Calculates the distance
 * Takes an array of [lat, lng]
 *
 * @param {array} currentLocation
 * @param {array} nodeLocation
 * @param {*} unit
 */
 export const calculateDistance = (currentLocation, nodeLocation) => {
  const [lon1, lat1] = currentLocation
  const [lon2, lat2] = nodeLocation

  //Radius of the earth in:  1.609344 miles,  6371 km  | var R = (6371 / 1.609344);
  var R = 3958.7558657440545; // Radius of earth in Miles
  var dLat = toRad(lat2 - lat1);
  var dLon = toRad(lon2 - lon1);
  var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(toRad(lat1)) * Math.cos(toRad(lat2)) *
    Math.sin(dLon / 2) * Math.sin(dLon / 2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var d = R * c;
  return d;
}