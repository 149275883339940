
import { useStaticQuery, graphql } from "gatsby"

export const useLocationServiceArea = () => {
  const data = useStaticQuery(graphql`
    query LocationServiceArea {
      allWpRescue {
        nodes {
      id
      title
      uri
      organizationInformation {
        yearsinoperation
        organizationtype
        organizationcharacteristicsselectallthatapply
        foodstoragecharacteristics
        transportcharacteristics
        website
        contactemailaddress
        publicemailaddress
        publicphonenumber
        isyourorganizationamemberofthefoodrescuealliance2
        isyourorganizationamemberofordoesithaveanaffiliationwithanothergroupsuchasfoodrescueusfeedingamericaorothers2
        pleaselisttheaffilatedgrouphere,
        organizationbusinessstatus,
      }
      locationServiceArea {
        rescueLat
        rescueLng
        mailingaddressCity
        mailingaddressLine1
        mailingaddressState
        mailingaddressPostalcode
        primaryaddressCity
        primaryaddressLine1
        primaryaddressLine2
        primaryaddressState
        primaryaddresszip
        whichcountiesorcitiesdoyoucommonlyrescuefoodfrom
        whatistheapproximatesizeofyourserviceareainmileagefromyourprimaryaddressorzipcodelistedabove
        whichzipcodesdoyoucommonlyrescuefoodfrom
        doyouhaveyourownphysicallocationanofficeorheadquartersstorageordistributionfacilitydirectaidsiteorotherlocation2
      }
      schedulingInformation {
        doyouoffersamedayrescuesorpickupoffood2
        howarerescuesandfoodpickupsscheduled
        linktoapplication
        rescueOperatingHours
        schedulewebsite
        whatdaysoftheweekdoyourescuefood
        whattimedoyoustartrescuingfoodonmostdays2
        whattimedoyoustoprescuingfoodonmostdays2
        whattimeofyeardoyourescuefood
        whichapplicationdoyouusetocoordinatefoodrescues
      }
      serviceDetails {
        staffcomposition2
        showbio
        pleaseprovideashortbio13sentencestoaccompanyyourlistingonthefoodrescuelocator
        parttimestaffsize
        numberofregularvolunteerspermonth
        linktovolunteerform
        isthereanythingelseweshouldknowaboutyouroperations
        fulltimestaffsize
        doyouprovidetaxdeductiondocumentationfordonors2
        doyouchargeforyourservices2
        areyoucurrentlyacceptingvolunteers2
        addmyemailtothesustainableamericaemaillist
      }
      foodRescueDetails {
        currentcapacityforrescueperindividualpickuplbsaverageorestimate
        currentcapacityforrescuepermonthlbsaverageorestimate
        whatrequirementsmustfooddonorsmeet
        whattypeoffoodsdoyourescue
        whattypeofstoragedoyouhaveforrescuedfood2
        wheredoyoudistributerescuedfoodto
        wheredoyourescuefoodfrom
        doesyourorganizationhaveanyotherspecialcertificationordesignation
        doyouacceptfoodpastitsexpirationdatesellbydateorbestbydate
        doyouhaveapreferredformatinwhichtoreceivedonations

      }
    }
  }
    }
  `)

  const locationServiceArea = data.allWpRescue.nodes

  return { locationServiceArea }
}
