import React from 'react';
// import "../styles/_LocationInput.scss"

const AddressInputAutoCompleteContainer = ({
  inputProps,
  suggestions,
  suggestionItemProps,
  loading,
  placeholder,
  showMap,
  status,
  required,
}) => {
  return (
    <>
      <input
        {...inputProps({
          placeholder: placeholder,
          className: `form-control`,
          required: required,
        })}
      />
      <div className="autocomplete-dropdown-container">
        {suggestions.map((suggestion, i) => {
          return (
            <div
              key={suggestion.placeId || i}
              {...suggestionItemProps(suggestion, {})}
              className="auto-complete-suggestion"
            >
              <span>{suggestion.description}</span>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default AddressInputAutoCompleteContainer;
