import React from "react";
import PropTypes from "prop-types";

const LocationIcon = ({ type, className, onClick, isActive }) => {
  const iconSize = isActive ? "70px" : "50px"

  return (
    <>
      <span onClick={onClick} className={`icon-location ${className}`}>
        {type === "Logistics Service Organization" ? (
          <img
            width={iconSize}
            src="/img/icons/logistics-org-icon.svg"
            role="presentation"
          ></img>
        ) : (
          <img
            width={iconSize}
            src="/img/icons/food-rescue-org-icon.svg"
            role="presentation"
          ></img>
        )}
      </span>
    </>
  );
};

LocationIcon.propTypes = {
  type: PropTypes.string.isRequired,
  onClick: PropTypes.instanceOf(Function),
};
LocationIcon.defaultProps = {
  className: "",
  onClick: () => {},
};

export default LocationIcon;
