import React from 'react'
import Search from '../components/home/Search'
import About from '../components/home/About'
import Locations from '../components/home/Locations'
import Layout from '../containers/Layout'
import SEO from '../containers/seo'

const Home = () => {
  return (
    <Layout>
      <div className='home-grid-wrapper'>
        <Search />
        <About />
        <Locations />
      </div>
    </Layout>
  )
}

export default Home

export const Head = () => (
  <>
    <title>Home | Food Rescue Locator</title>
  </>
)
