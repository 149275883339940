import React from "react";
// import PropTypes from 'prop-types'
import { OverlayTrigger } from "react-bootstrap";
import { Popover } from "react-bootstrap";

const markerStyles = {
  width: "20px",
  height: "20px",
  backgroundColor: "#5fb35f",
  border: "2px solid #fff",
  borderRadius: "50% 50% 50% 0",
  transform: "rotate(-45deg)",
};

const Marker = ({ text }) => {
  return (
    <OverlayTrigger
      trigger="click"
      placement="top"
      overlay={
        <Popover>
          <Popover.Title as="h3">
            <strong>{text}</strong>
          </Popover.Title>
          <Popover.Content></Popover.Content>
        </Popover>
      }
    >
      <div style={markerStyles} className="map-marker"></div>
    </OverlayTrigger>
  );
};

Marker.propTypes = {};

export default Marker;
