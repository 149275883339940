import React from 'react'
import { Link } from "gatsby";

const About = () => {
  return (
    <div className="grid-item about">
      <h2 className="h4 text-white">Welcome to the Food Rescue Locator</h2>
      <p>Food rescue organizations play an important role in feeding the hungry and reducing food waste by rescuing, gleaning, transporting, preparing and distributing food to people and communities in need. This directory of organizations across the United States serves as a resource for individuals and groups to find food recovery organizations to which they can donate excess food, or support in other ways.</p>
      {/* <Link to="/about" className="btn btn-light">About</Link> */}
    </div>
  )
}

export default About
