import React from 'react';
import PropTypes from 'prop-types';
import GoogleMapReact from 'google-map-react';

// const Wrapper = styled.main`
//   width: 100%;
//   height: 33vw;
// `
const GoogleMap = ({ children, ...props }) => (
  // Options to pass in: https://developers.google.com/maps/documentation/javascript/reference/map?hl=en#MapOptions.draggable
  <>
    <GoogleMapReact
      bootstrapURLKeys={{
        key: process.env.GATSBY_MAP_KEY,
      }}
      {...props}
    >
      {children}
    </GoogleMapReact>
  </>
);

GoogleMap.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
};

GoogleMap.defaultProps = {
  children: null,
};

export default GoogleMap;
