import React from 'react'
import { useQueryParamString } from 'react-use-query-param-string'
import { openDrawer } from '../../../common/drawers/drawerActions'

const ListItem = ({ data, isActive }) => {
  const {
    title,
    distanceFromLocation,
    locationServiceArea: {
      primaryaddressLine1,
      primaryaddressCity,
      primaryaddressState
    },
    organizationInformation: { organizationtype }
  } = data
  const [locId, setLocId] = useQueryParamString('loc', '')

  const readMoreAction = () => {
    setLocId(data.id)

    openDrawer('LocationDetailDrawer', { data })
  }

  return (
    <div className={`container-fluid py-4 ${isActive ? 'bg-secondary' : ''}`}>
      <div className='row d-flex align-items-center'>
        <div className='col-1 d-flex justify-content-center'>
          {organizationtype === 'Logistics Service Organization' ? (
            <img
              src='/img/icons/logistics-org-icon.svg'
              role='presentation'
            ></img>
          ) : (
            <img
              src='/img/icons/food-rescue-org-icon.svg'
              role='presentation'
            ></img>
          )}
        </div>
        <div className='col-11 col-md-4'>
          <h4 className='mb-0 text-gray'>{title}</h4>
          {primaryaddressLine1 && primaryaddressCity && primaryaddressState && (
            <h6 className='mb-0 text-gray text-normal text-capitalize'>
              {primaryaddressLine1}. | {primaryaddressCity},{' '}
              {primaryaddressState}
            </h6>
          )}
        </div>
        {/* <div className="col-5 offset-1 offset-md-0 col-md-3 text-md-center">
          <h5>
            {organizationtype === "Logistics Service Organization"
              ? "Logistics Org"
              : "Food Rescue Org"}
          </h5>
        </div> */}
        <div className='col-2 col-md-3 offset-1 offset-md-0 text-md-center mt-4 mt-md-0'>
          {distanceFromLocation && (
            <h6 className='text-lowercase text-gray text-normal'>
              {parseFloat(distanceFromLocation).toFixed(2)} miles
            </h6>
          )}
        </div>
        <div className='col-5 col-md-4 d-flex justify-content-md-end mt-4 mt-md-0'>
          <button
            className='btn btn-dark mt-0'
            onClick={() => readMoreAction()}
          >
            Read More
          </button>
        </div>
      </div>
    </div>
  )
}

export default ListItem
