import React, { useState, useEffect } from 'react'
import MapDashboard from './components/MapDashboard'
import { useDispatch } from 'react-redux'
import { openModal } from '../../common/modals/modalActions'
import ListDashboard from './components/ListDashboard'
import { useLocationServiceArea } from '../../common/hooks/useLocationServiceArea'
import { calculateDistance } from '../../common/utils/helpers'
import { useQueryParamString } from 'react-use-query-param-string'

const Locations = () => {
  const dispatch = useDispatch()
  const { locationServiceArea } = useLocationServiceArea()
  const [filteredLocations, setFilteredLocations] = useState([])
  const [lat] = useQueryParamString('lat', '')
  const [lng] = useQueryParamString('lng', '')
  const [loadedView, setLoadedView] = useQueryParamString('view', '')

  useEffect(() => {
    if (locationServiceArea && locationServiceArea.length && lat && lng) {
      // sort by distance
      const filteredSet = locationServiceArea.map((loc) => {
        const {
          locationServiceArea: { rescueLat, rescueLng }
        } = loc
        loc.distanceFromLocation = calculateDistance(
          [lat, lng],
          [rescueLat, rescueLng]
        )
        return loc
      })

      filteredSet.sort(function (a, b) {
        return a.distanceFromLocation - b.distanceFromLocation
      })
      setFilteredLocations(filteredSet)
    } else {
      setFilteredLocations(locationServiceArea)
    }
  }, [locationServiceArea, lat, lng])

  return (
    <div className='locations'>
      {filteredLocations && filteredLocations.length > 0 && (
        <>
          {loadedView === 'list' ? (
            <ListDashboard data={filteredLocations} />
          ) : (
            <MapDashboard data={filteredLocations} />
          )}
        </>
      )}
      <div className='map-toggle'>
        <button
          id='mapViewToggler'
          className={!loadedView || loadedView === 'map' ? 'active' : ''}
          onClick={() => {
            setLoadedView('map')
          }}
        >
          Map
        </button>
        <div className='border-end d-inline-block mx-2'>&nbsp;</div>
        <button
          id='listViewToggler'
          className={loadedView === 'list' ? 'active' : ''}
          onClick={() => {
            setLoadedView('list')
          }}
        >
          List
        </button>
      </div>
      <div className='map-key'>
        <ul>
          <li id='foodRescueOrgLegend'>Food Rescue Org</li>
          <li id='logisticsOrgLegend'>Logistics Org</li>
        </ul>
        <button
          id='mapKeyInfo'
          onClick={() => {
            dispatch(openModal('OrgTypeModal'))
          }}
        ></button>
      </div>
    </div>
  )
}

export default Locations
